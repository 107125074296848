@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #__next {
    height: 100%
}

.dropdown:focus-within .dropdown-menu {
    opacity:1 !important;
    transform: translate(0) scale(1) !important;
    visibility: visible !important;
}
